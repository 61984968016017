import { alpha, createTheme, responsiveFontSizes } from "@mui/material/styles";
import { DateFormatEnum } from "constants/DateContants";

export const lightTheme = customizeComponents({});
export const darkTheme = customizeComponents({ palette: { mode: "dark" } });

/**
 *
 * @param {import("@mui/material").Theme} theme
 */
export function customizeComponents(theme) {
  return responsiveFontSizes(
    createTheme({
      ...theme,
      palette: {
        ...theme.palette,
        primary: {
          lighter: "#EEF7FF",
          main: "#152D4A",
        },
        secondary: {
          // main: "#FFE600",
          lighter: "#FFF8DB",
          light: "#FFDE5B",
          main: "#FFCC00",
          dark: "#C8A000",
          darker: "#5B4900",
        },
        tertiary: {
          main: "#0176FF",
          contrastText: "#FFFFFF",
        },
        background: { default: "#F8F8F8", ...theme.palette?.background },
      },

      breakpoints: {
        values: {
          xs: 0,
          sm: 640,
          md: 768,
          lg: 1024,
          xl: 1280,
          "2xl": 1536,
        },
      },
      typography: {
        fontFamily: [
          "'Sora'",
          "Nexa",
          "sans-serif",
          "-apple-system",
          "BlinkMacSystemFont",
          "Segoe UI",
          "Roboto",
          "Oxygen",
          "Ubuntu",
          "Cantarell",
          "Fira Sans",
          "Droid Sans",
          "Helvetica Neue",
          "sans-serif",
        ].join(),
        fontSize: 12,
        // button: {
        //   // textTransform: "none",
        // },
      },
      components: {
        MuiContainer: {
          defaultProps: {
            maxWidth: "xl",
          },
        },
        MuiIcon: {
          defaultProps: {
            baseClassName: "material-icons-outlined",
          },
        },
        MuiDatePicker: {
          defaultProps: {
            inputFormat: DateFormatEnum.FORMAT,
          },
        },
        MuiDesktopDatePicker: {
          defaultProps: {
            inputFormat: DateFormatEnum.FORMAT,
          },
        },
        MuiMobileDatePicker: {
          defaultProps: {
            inputFormat: DateFormatEnum.FORMAT,
          },
        },
        MuiTabs: {
          defaultProps: {
            variant: "scrollable",
            scrollButtons: "auto",
            allowScrollButtonsMobile: true,
          },
        },
        MuiLoadingButton: {
          defaultProps: {
            disableElevation: true,
            variant: "contained",
          },
        },
        MuiButton: {
          defaultProps: {
            disableElevation: true,
            variant: "contained",
          },
          variants: [
            {
              props: { borderradius: "default" },
              style: ({ theme }) => ({}),
            },
            {
              props: { borderradius: "square" },
              style: ({ theme }) => ({ borderRadius: 0 }),
            },
            {
              props: { borderradius: "circular" },
              style: ({ theme }) => ({ borderRadius: 24 }),
            },
          ],
          styleOverrides: {
            root: ({ theme, ownerState }) => ({
              fontWeight: "bold",
              ...(!isNaN(Number(ownerState.borderradius))
                ? { borderRadius: Number(ownerState.borderradius) }
                : {}),
            }),
          },
        },
        MuiTextField: {
          defaultProps: {
            // InputLabelProps: { shrink: true },
          },
        },
        MuiInputBase: {
          defaultProps: {},
          styleOverrides: {
            root: ({ theme, ownerState }) => ({
              // backgroundColor:
              //   theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
              // "label + &": {
              //   marginTop: theme.spacing(2),
              // },
              // "& .MuiOutlinedInput-notchedOutline": {
              //   "& legend": { maxWidth: 0 },
              // },
            }),
          },
        },
        MuiDialog: {
          defaultProps: {
            maxWidth: "xs",
          },
        },
      },
    })
  );
}
