export const RouteEnum = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  VERIFY: '/verify',
  GET_ESTIMATE: '/get-estimate',
  GET_ESTIMATE_VALUE: '/get-estimate-value',
  PASSWORD_RESET_REQUEST: '/forgot-password',
  PASSWORD_RESET: '/reset-password',
  PROFILE: '/profile',
  VEHICLES: '/vehicles',
  VEHICLES_REGISTER: '/vehicles/register',
  VEHICLES_EDIT: '/vehicles/:plateNumber/edit',
  VEHICLES_DOCUMENTS: '/vehicles/:plateNumber/documents',
  DOCUMENTS: '/documents',
  TRIPS: '/trips',
  PROMOTIONS: '/promotions',
  PROMOTIONS_DETAILS: '/promotions/:id',
  ABOUT_US: '/about-us',
  ABOUT_HOW_GO_WORKS: '/how-go-works',
  PARTNER: '/become-a-partner',
  CAREER: '/career',
  CONTACT: '/contact',
  PRIVACY: '/privacy',
  TERMS_CONDITIONS: '/terms-conditions',
  TERMS_AND_CONDITIONS: '/terms-&-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  PRIVACY_AND_POLICY: '/privacy-&-policy',
  DOWNLOAD_APP: '/download-app',
  DOWNLOAD_APP_SHORT: '/d',
  LOGISTICS: '/',
  LOGISTICS_LOGIN: '/login',
  LOGISTICS_OTP_VERIFICATION: '/otp-verification',
  LOGISTICS_SEND: '/send',
  LOGISTICS_TRACK: '/track',
  LOGISTICS_TRACK_DETAILS: '/track/:number',
};
