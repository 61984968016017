import { createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "./StoreActionConfig";
import AccountApi from "apis/AccountApi";
import { getAppSubdomain } from "utils/GlobalUtils";
import { SubDomainEnum } from "constants/Global";

export const globalInitialState = {
  themeMode: "light", // 'dark'| 'light' | 'media'
  isLoadingModal: false,
  isSideMenu: false,
};

const slice = createSlice({
  name: "global",
  initialState: globalInitialState,
  reducers: {
    toggleLoadingModalAction: (state, { payload }) => {
      state.isLoadingModal =
        payload !== undefined ? !!payload : !state.isLoadingModal;
    },
    toggleSideMenuAction: (state, { payload }) => {
      state.isSideMenu = payload !== undefined ? !!payload : !state.isSideMenu;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logoutAction, () => ({ ...globalInitialState }))
      .addMatcher(
        AccountApi.endpoints.authenticateAccount.matchFulfilled,
        (state, { payload }) => {
          const subdomain = getAppSubdomain();
          state[subdomain] = {
            ...state[subdomain],
            token: payload?.token,
          };
        }
      )
      .addMatcher(
        AccountApi.endpoints.registerAccount.matchFulfilled,
        (state, { payload }) => {
          const subdomain = getAppSubdomain();
          state[subdomain] = {
            ...state[subdomain],
            token: payload?.token,
          };
        }
      )
      .addMatcher(
        AccountApi.endpoints.sendAccountOtp.matchFulfilled,
        (state, { payload }) => {
          // const subdomain = getAppSubdomain();
          // state[subdomain] = {
          //   ...state[subdomain],
          //   token: payload?.token,
          // };
        }
      )
      .addMatcher(
        AccountApi.endpoints.verifyAccountOtp.matchFulfilled,
        (state, { payload }) => {
          const subdomain = getAppSubdomain();
          state[subdomain] = {
            ...state[subdomain],
            token: payload?.token,
          };
        }
      )
      .addMatcher(
        AccountApi.endpoints.getAccount.matchFulfilled,
        (state, { payload }) => {
          const subdomain = getAppSubdomain();
          state[subdomain] = { ...state[subdomain], ...payload };
        }
      )
      .addMatcher(
        AccountApi.endpoints.getAccountProfilePic.matchFulfilled,
        (state, { payload }) => {
          const subdomain = getAppSubdomain();
          state[subdomain] = { ...state[subdomain], avatar: payload };
        }
      ),
});

export const { toggleLoadingModalAction, toggleSideMenuAction } = slice.actions;

export default slice;

export function getGlobalSliceStorageState(state) {
  let subdomain = getAppSubdomain();
  subdomain =
    subdomain === SubDomainEnum.LOGISTICS ? SubDomainEnum.DELIVERY : subdomain;
  return { [subdomain]: state[subdomain] };
}
