export const StoreQueryTagEnum = {
  USER: "USER",
  RATING: "RATING",
  VEHICLE: "VEHICLE",
  TRIP: "TRIP",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  DELIVERY: "DELIVERY",
  PROMOTION: "PROMOTION",
  BONUS: "BONUS",
};
