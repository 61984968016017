export const EnvKeyEnum = {
  PORT: process.env.REACT_APP_PORT,
  API_URL: process.env.REACT_APP_API_URL,
  PAYSTACK_CHECKOUT_JS: process.env.REACT_APP_PAYSTACK_CHECKOUT_JS,
  PAYSTACK_PUBLIC_KEY: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
  FLUTTERWAVE_CHECKOUT_JS: process.env.REACT_APP_FLUTTERWAVE_CHECKOUT_JS,
  FLUTTERWAVE_PUBLIC_KEY: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
  REMITA_CHECKOUT_JS: process.env.REACT_APP_REMITA_CHECKOUT_JS,
  REMITA_PUBLIC_KEY: process.env.REACT_APP_REMITA_PUBLIC_KEY,
  GO_RIDER_APPSTORE_LINK:
    process.env.REACT_APP_GO_RIDER_APPSTORE_LINK ||
    "https://apps.apple.com/ng/app/go-ride-delivery-groceries/id6444678067",
  GO_RIDER_PLAYSTORE_LINK:
    process.env.REACT_APP_GO_RIDER_PLAYSTORE_LINK ||
    "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.gocaby.rider",
  GO_DRIVER_APPSTORE_LINK:
    process.env.REACT_APP_GO_DRIVER_APPSTORE_LINK ||
    "https://apps.apple.com/ng/app/go-driver-drive-deliver-earn/id1668681299",
  GO_DRIVER_PLAYSTORE_LINK:
    process.env.REACT_APP_GO_DRIVER_PLAYSTORE_LINK ||
    "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.gocaby.driverapp",
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  WHATSAPP_PHONE_NUMBER: process.env.REACT_APP_WHATSAPP_PHONE_NUMBER || "+2348086369466",
  TELEGRAM_USERNAME: process.env.REACT_APP_TELEGRAM_USERNAME || "+RtqG7VqcHrM2NDVk",
};
