import { logoutAction } from "configs/StoreActionConfig";
import { AppApi } from "configs/StoreQueryConfig";
import { UserTypeEnum } from "constants/Global";
import { navigateRef } from "constants/RefConstants";
import { RouteEnum } from "constants/RouteConstants";
import { StoreQueryTagEnum } from "constants/StoreConstants";
import { enqueueSnackbar } from "notistack";
import { getAppSubdomainBooleans } from "utils/GlobalUtils";
import { objectToFormData } from "utils/ObjectUtils";
import { providesTags, invalidatesTags } from "utils/QueryUtils";

export const ACCOUNT_API_URL = "/account";

export const AccountApi = AppApi.injectEndpoints({
  endpoints: (builder) => ({
    // account controller
    registerAccount: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1`,
        method: "post",
        ...config,
      }),
    }),
    authenticateAccount: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/authenticate`,
        method: "post",
        ...config,
      }),
    }),
    // file upload controller
    uploadAccountProfilePic: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/uploads/profile-pic`,
        method: "post",
        ...config,
        data: objectToFormData(config.data),
      }),
      invalidatesTags: (data, error) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER),
    }),
    // instrospect
    getAccountInstrospect: builder.query({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/instrospect`,
        ...config,
      }),
      providesTags: (data) =>
        providesTags([data?.data], StoreQueryTagEnum.USER),
    }),
    // otp authentication controller
    sendAccountOtp: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/otp/send`,
        method: "post",
        ...config,
      }),
      invalidatesTags: (data, error) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER),
    }),
    verifyAccountOtp: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/otp/verify`,
        method: "post",
        ...config,
      }),
      invalidatesTags: (data, error) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER),
    }),
    // password controller
    sendPasswordResetRequest: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/password/reset/send`,
        method: "post",
        ...config,
      }),
    }),
    resetPassword: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/password/reset`,
        method: "post",
        ...config,
      }),
    }),
    changePassword: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/password/change`,
        method: "post",
        ...config,
      }),
    }),
    // profile controller
    getAccountBasicDetails: builder.query({
      query: ({ path, ...config }) => ({
        url: `${ACCOUNT_API_URL}/v1/accounts/${path.accountId}`,
        ...config,
      }),
      providesTags: (data) =>
        providesTags([data?.data], StoreQueryTagEnum.USER),
    }),
    getAccount: builder.query({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/me`,
        ...config,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const invalidAccountError = new Error("Invalid Account");
        try {
          const data = await queryFulfilled;
          // if (
          //   data?.data?.accountType === UserTypeEnum.RIDER &&
          //   getAppSubdomainBooleans().isRideDomain
          // ) {
          //   throw invalidAccountError;
          // }
        } catch (error) {
          if (error === invalidAccountError) {
            dispatch(logoutAction());
            enqueueSnackbar(invalidAccountError.message, { variant: "error" });
            navigateRef.current(RouteEnum.LOGIN);
          }
        }
      },
      providesTags: (data) =>
        providesTags([data?.data], StoreQueryTagEnum.USER),
    }),
    updateAccount: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/me`,
        method: "put",
        ...config,
      }),
      invalidatesTags: (data, error) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER),
    }),
    getAccountProfilePic: builder.query({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/profile-pic`,
        ...config,
      }),
      providesTags: (data) =>
        providesTags([data?.data], StoreQueryTagEnum.USER),
    }),

    activateAccountWallet: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/activate-wallet`,
        method: "POST",
        ...config,
      }),
    }),

    // kyc
    saveAccountKyc: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/kyc`,
        method: "PUT",
        ...config,
      }),
    }),
  }),
});

export default AccountApi;
