import { SubDomainEnum } from 'constants/Global';

export function getAppSubdomain() {
  const splits = window.location.host.split(/[\\.:]/);
  if (splits?.[0] === 'dev') {
    return splits[1];
  }
  return splits?.[0];
}

export function getAppSubdomainBooleans(subdomain = getAppSubdomain()) {
  const isRideDomain = [SubDomainEnum.RIDE].includes(subdomain);
  const isShopDomain = [SubDomainEnum.SHOP].includes(subdomain);
  const isLogisticsDomain = [
    SubDomainEnum.LOGISTICS,
    SubDomainEnum.DELIVERY,
  ].includes(subdomain);
  const isDefault =
    [SubDomainEnum.DEFAULT, 'localhost', '127.0.0.1'].includes(subdomain) ||
    !(isRideDomain || isLogisticsDomain || isShopDomain);
  return { isDefault, isRideDomain, isLogisticsDomain, isShopDomain };
}
