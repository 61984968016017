import axios from "axios";
import { EnvKeyEnum } from "constants/EnvConstants";

export const AppHttp = axios.create({
  baseURL: EnvKeyEnum.API_URL,
});

export const CloudinaryHttp = axios.create({
  baseURL: EnvKeyEnum.CLOUDINARY_API_URL,
});

export default AppHttp;
