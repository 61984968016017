const tailwindDefaultTheme = require('tailwindcss/defaultTheme');

export const PORT = process.env.REACT_APP_PORT || 3000;
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_DEVELOPEMENT = process.env.NODE_ENV === 'development';

export const MediaQueryBreakpointEnum = {
  '2xl': `(min-width: ${tailwindDefaultTheme.screens['2xl']})`,
  lg: `(min-width: ${tailwindDefaultTheme.screens.lg})`,
  md: `(min-width: ${tailwindDefaultTheme.screens.md})`,
  sm: `(min-width: ${tailwindDefaultTheme.screens.sm})`,
  xl: `(min-width: ${tailwindDefaultTheme.screens.xl})`,
};

export const APP_SIDE_MENU_WIDTH = 270;

export const PaginationParamsDefault = {
  offset: 0,
  limit: 20,
};

export const PaginationDefault = {
  pageIndex: 0,
  pageSize: 20,
};

export const SubDomainEnum = {
  RIDE: 'ride',
  LOGISTICS: 'logistics',
  DELIVERY: 'delivery',
  SHOP: 'food',
  DEFAULT: 'gocaby',
};

const logisticsSubdomain = window.location.hostname.startsWith(
  SubDomainEnum.LOGISTICS
)
  ? SubDomainEnum.LOGISTICS
  : SubDomainEnum.DELIVERY;

export const AppDomainEnum = {
  RIDE: IS_DEVELOPEMENT
    ? `http://${SubDomainEnum.RIDE}.localhost:${PORT}`
    : `https://${SubDomainEnum.RIDE}.gocaby.com`,
  LOGISTICS: IS_DEVELOPEMENT
    ? `http://${logisticsSubdomain}.localhost:${PORT}`
    : `https://${logisticsSubdomain}.gocaby.com`,
  DEFAULT: IS_DEVELOPEMENT ? `http://localhost:${PORT}` : `https://gocaby.com`,
  SHOP: IS_DEVELOPEMENT
    ? `http://${SubDomainEnum.SHOP}.localhost:${PORT}`
    : `https://${SubDomainEnum.SHOP}.gocaby.com`,
};

export const DeviceEnum = {
  ANDROID: 'Android',
  IPHONE: 'iPhone',
  IPAD: 'iPad',
};

export const DeviceDetectedEnum = {
  ANDROID: navigator.userAgent.match(/Android/i),
  IPHONE: navigator.userAgent.match(/iPhone/i),
  IPAD: navigator.userAgent.match(/iPad/i),
  IOS: navigator.userAgent.match(/iPhone|iPad/i),
};

export const UserTypeEnum = {
  DRIVER: 'DRIVER',
  RIDER: 'RIDER',
};
